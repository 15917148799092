'use strict';

var FlyoutSearch = (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT === true) ? require('./monetate-flyoutsearch') : require('./flyoutsearch');
var MenuSearchUtil = require('./menu-search-util');

function menuSearch($searchContainer, $nav, $defaultValue) {
    new MenuSearchUtil($searchContainer, $nav, $defaultValue, FlyoutSearch).init();
}

module.exports = menuSearch;

'use strict';

/* banner logic  - Moved from search.js */

//banner is fixed
//starts below promotion on transparent header
//starts below the nav for opaque header

function initBannerScroll() {
    var banner = document.querySelector('.cat-banner-cont');
    var isPLP = $('.pt_product-search-result').hasClass('plp');
    if (banner && !isPLP) {
        var wrapper = document.querySelector('#wrapper');
        var mainContent = document.querySelector('#main');
        var header = document.querySelector('header');
        var topnavCont = header.querySelector('.top-banner-sticky');
        var navigation = header.querySelector('.top-banner');
        var bannerCont = banner.closest('.slot-grid-header');

        var bannerInfos = [];

        var promoAtTop;
        var promoBottomDesktop = $('header').hasClass('promo-bottom-desktop');
        var promoBottomMobile = $('header').hasClass('promo-bottom-mobile');
        var navigationHeight = navigation.offsetHeight;
        var promo = document.querySelector('.header-banner');
        var promoHeight = promo.offsetHeight;
        var criticalBannerHeight = header.querySelector('.global-critical-err-msg') ? header.querySelector('.global-critical-err-msg').offsetHeight : 0;
        var transparentNav = header.className.indexOf('transparent') > -1;
        var nonStickyPromo = header.querySelector('.no-sticky-banner');
        var isStuck = null;
        var isWishlistPage = (pageContext.ns === 'wishlist');
        var isMyrlAccount = (pageContext.type === 'myRL-MyAccount');
        var isMyrlAccountPPLP = (pageContext.type === 'myRL-MyAccount-PPLP');


        if ((!promoBottomDesktop && $(window).width() >= 960) || (!promoBottomMobile && $(window).width() <= 959)) {
            promoAtTop = true;
        } else {
            promoAtTop = false;
        }

        function bannerScroll(e) {
            var pos = $(window).scrollTop();
            var bannerHeight = banner.offsetHeight;

            $('#container-wrap').css({'min-height': bannerHeight, 'transform': 'translate3d(0, 0, 0)'}); 
            var positionCheck = (isWishlistPage || isPLP) ? (pos > bannerHeight) : (pos > 2 * bannerHeight);

            if (positionCheck && (!isMyrlAccountPPLP || !isMyrlAccount)) {
                banner.style.opacity = '0';
            } else {
                banner.style.opacity = '1';
            }

            var opacityVal = (1 - pos / (0.7 * bannerHeight));
            var $wishlistBannerContainer = $('.wl-banner-container section.content');

            if (isWishlistPage && $wishlistBannerContainer.length > 0) {
                $wishlistBannerContainer.css('opacity', opacityVal);
            } else {
                bannerInfos.forEach(function(el) {
                    el.style.opacity = opacityVal;
                });
            }
        }

        if (bannerCont && !isMyrlAccount && !isWishlistPage) {
            bannerCont.style['position'] = 'sticky';
            bannerInfos = Array.from(banner.querySelectorAll('.plp-info'));
            if (nonStickyPromo && promoAtTop) {
                if (transparentNav) {
                    banner.style['margin-top'] = '-' + (navigationHeight + (promoAtTop ? 0 : promoHeight)) + 'px';
                    bannerCont.style['top'] =  '0px';
                } else {
                    bannerCont.style['top'] =  navigationHeight + 'px';
                }
            } else if (nonStickyPromo && !promoAtTop) {
                // not compatible with transparent Nav
                bannerCont.style['top'] = (navigationHeight ) + 'px';
            } else {
                if (transparentNav) {
                    banner.style['margin-top'] = '-' + (navigationHeight + (promoAtTop ? 0 : promoHeight)) + 'px';
                    bannerCont.style['top'] =  (promoAtTop ? promoHeight : 0) + 'px';
                } else {
                    bannerCont.style['top'] = (navigationHeight + promo.offsetHeight) + 'px';
                }
            }
        }

        $(window).on('scroll', bannerScroll);
        bannerScroll();
    }
}
exports.init = function () {
	initBannerScroll();
};
'use strict';

var util = require('./util'),
    progress = require('./progress'),
    producttile =  require('./product-tile');
var { reveal: fadeEffect } = require('@foundation/js/animationsUtils');

function appendPage(page) {
    var pages = window.history.state || [];
    if (pages.filter(function (p) { return p.count == page.count}).length > 0) {
        return pages;
    } else {
        pages.push(page);
        return pages;
    }
}

function getTotalProductCount() {
    var count = ($('input[name=totalProductsCount]').length > 0)
        ? parseInt($('input[name=totalProductsCount]').val())
        : parseInt($('input[name=productsCount]').last().val());
    return count;
}

function savePageState(response, grid, ajaxUrl) {
	if (grid == null || grid == '' || grid == 'null') {
		grid = 'default';
	}
	try {
		var pagingString = sessionStorage.getItem('wishlistpagination');
		var pagingJSON;
		if (pagingString != null) {
			pagingJSON = JSON.parse(pagingString);
			if (!(grid in pagingJSON)) {
				pagingJSON[grid] = new Array();
			}
			if (!('url' in pagingJSON)) {
				pagingJSON.url = window.location.href;
			}
		} else {
			pagingJSON = {};
			pagingJSON.url = window.location.href;
			pagingJSON[grid] = new Array();
		}
		var pagePreLoaded = false;
		for (var i = 0; i < pagingJSON[grid].length; i++) {
			if (pagingJSON[grid][i] == ajaxUrl) {
				pagePreLoaded = true;
			}
		}
		if (!pagePreLoaded) {
			pagingJSON[grid].push(ajaxUrl);
		}

		sessionStorage.setItem('wishlistpagination', JSON.stringify(pagingJSON));
		var productCount = getTotalProductCount();
	    var pages = appendPage({count: productCount, response: response, scrollTop: $(window).scrollTop()});
	    sessionStorage.setItem(ajaxUrl, JSON.stringify(pages));
	} catch (e) {}
}

function emptyProduct() {
    $('ul.search-result-items li.plp-custom-image-section').each(function() {
        var $this = $(this);
        if ($this.children().length == 0) {
            $this.remove();
        }
    });
}



function loadMoreProductListing(url, $scroll, gridFilter, divisionFilter) {
    if (!url || url === window.location.href) {
        return;
    }
    var ajaxUrl = util.appendParamToURL(url, 'format', 'ajax');
    if (gridFilter != '' && gridFilter != null & gridFilter != 'null') {
        progress.show($('#bottom-pagination[grid="' + gridFilter + '"] .pagination-flex'));
    } else {
        progress.show($('#bottom-pagination .pagination-flex'));
    }
    $.ajax({
        type: 'GET',
        dataType: 'html',
        url: ajaxUrl,
        success: function (response) {
            // put response into cache
            try {
                savePageState(response, gridFilter, ajaxUrl);
            } catch (e) {
                // nothing to catch in case of out of memory of session storage
                // it will fall back to load via ajax
            }
            progress.hide();
            // update UI
            var $data = $('<div>').append($.parseHTML(response));
            var isLastElement = false;
            if (gridFilter != '' && gridFilter != null & gridFilter != 'null') {
                $('div.search-result-content[grid="' + gridFilter + '"]').append($data.find('.search-result-content > *'));
                var $tilesToFade = $('div.search-result-content[grid="' + gridFilter + '"] .search-result-items:last-child .grid-tile').slice(0,12).css({opacity:0});
                fadeEffect($tilesToFade);

                // checking for the last element from the loaded product tile to then load monetate swipe js
                var checkLastGridTile = setInterval(function() {
                    if($('div.search-result-content[grid="' + gridFilter + '"] .search-result-items:last-child .grid-tile').last().css('display') != 'none') {
                        clearInterval(checkLastGridTile);
                    }
                }, 100);

                if ($data.find('#bottom-pagination .pagination').length > 0) {
                    $('#bottom-pagination[grid="' + gridFilter + '"]').html($data.find('#bottom-pagination').html());
                } else {
                    $('#bottom-pagination[grid="' + gridFilter + '"]').remove();
                }
            } else if (divisionFilter != '' && divisionFilter != null & divisionFilter != 'null') {
                $('div.search-result-content[division="' + divisionFilter + '"]').append($data.find('.search-result-content > *'));
                var $tilesToFade = $('div.search-result-content[division="' + divisionFilter + '"] .search-result-items:last-child .grid-tile').slice(0,12).css({opacity:0});
                fadeEffect($tilesToFade);

                if ($data.find('#bottom-pagination .pagination').length > 0) {
                    $('#bottom-pagination[division="' + divisionFilter + '"]').html($data.find('#bottom-pagination').html());
                    var moreUrl = $('#bottom-pagination[division="' + divisionFilter + '"]').find('.more-button');
                    var appeneddUrl = util.appendParamsToUrl(moreUrl.prop('href'), {division: divisionFilter})
                    moreUrl.prop('href', appeneddUrl)
                } else {
                    $('#bottom-pagination[division="' + divisionFilter + '"]').remove();
                }
            } else {
                $('div.search-result-content').append($data.find('.search-result-content > *'));
                var $tilesToFade = $('div.search-result-content .search-result-items:last-child .grid-tile').slice(0,12).css({opacity:0});
                fadeEffect($tilesToFade);

                // checking for the last element from the loaded product tile to then load monetate swipe js
                var checkLastGridTile = setInterval(function() {
                    if($('div.search-result-content .search-result-items:last-child .grid-tile').last().css('display') != 'none') {
                        clearInterval(checkLastGridTile);
                    }
                }, 100);

                $('#bottom-pagination').html($data.find('#bottom-pagination').html());
            }
            producttile.init();
            //wishlist.setFavourites();
            require.ensure([], function (require) {
                var chunk = require('@foundation/js/pages/wishlistStorage');
                if (chunk.setFavourites) {
                    chunk.setFavourites();
                }
            }, function (error) {
                console.log('error!', error);
            }, 'wishlist');
            emptyProduct();

            if (!isLastElement){
                document.dispatchEvent(new CustomEvent('loadMoreLoaded', {detail: url}));
            }
        }
    });
}

function initPageLoad() {
    $(document).on('click', '.pagination a', function (e) {
        e.preventDefault();
        if (e.target.classList.contains('more-button')){
            var scroll = $(window).scrollTop();
            var curVal = this.href;
            loadMoreProductListing(curVal, scroll, $(this).attr('grid'), $(this).attr('division'));
        } else if (e.target.classList.contains('view-all')){
            var $paginationContainer = $(this).closest('#bottom-pagination');
            var triggerNextPage = function (){
                var windowHeight = window.innerHeight ;
                var paginationTop = document.getElementById('bottom-pagination').getBoundingClientRect().top;
                // trigger 200px before pagination appears within viewport
                if(paginationTop < (windowHeight + 200)){
                    $(window).off('scroll', triggerNextPage);
                    $paginationContainer.find('.more-button').click();
                }
            }
            document.addEventListener('loadMoreLoaded',function (e){
                if ($paginationContainer.find('.more-button').length){
                    // wait for html to actually render a bit because of lazy loading
                    setTimeout(function (){
                        $(window).on('scroll', triggerNextPage);
                        triggerNextPage();
                    }, 1000)
                }
            })
            $paginationContainer.find('.more-button').click();
            $paginationContainer.addClass('view-all-active');
        }
    });
}

function updateSearchPageUI(response, scrollTop, grid) {
    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    var fillEndlessScrollChunk = function (html, grid) {
    	if (grid == 'default') {
	        var $data = $('<div>').append($.parseHTML(html)); 
	    	$('div.search-result-content').append($data.find('.search-result-content > *'));
	    	$('#bottom-pagination').html($data.find('#bottom-pagination').html());
    	} else {
    		var $data = $('<div>').append($.parseHTML(html));
	        $('div.search-result-content[grid="' + grid + '"]').append($data.find('.search-result-content > *'));
	        if ($data.find('#bottom-pagination .pagination').length > 0) {
        		$('#bottom-pagination[grid="' + grid + '"]').html($data.find('#bottom-pagination').html());
        	} else {
        		$('#bottom-pagination[grid="' + grid + '"]').remove();
        	}
    	}
    };

    // update UI
    fillEndlessScrollChunk(response, grid);
    producttile.init();
    emptyProduct();
    var productCount = getTotalProductCount();
    $('span.productsCountUpdate').text(productCount);

    //wishlist.init();
    require.ensure([], function (require) {
        var chunk = require('./pages/wishlist');
        if (chunk.init) {
            chunk.init();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
    if (scrollTop) {
        $(window).scrollTop(scrollTop);
    }
}

function clearSessionStorage() {
	var pagingString = sessionStorage.getItem('wishlistpagination');

	if (pagingString != null) {
		var pagingJSON = JSON.parse(pagingString);
		if ('url' in pagingJSON && pagingJSON.url != window.location.href) {
			var gridKeys = Object.keys(pagingJSON);
			for (var i = 0; i < gridKeys.length; i++) {
				var gridKey = gridKeys[i];
				if (gridKey != 'url') {
					var loadMoreKeys = Object.keys(pagingJSON[gridKey]);
					for (var j = 0; j < loadMoreKeys.length; j++) {
						var loadMoreKey = loadMoreKeys[j];
						delete pagingJSON[gridKey][loadMoreKey];
						sessionStorage.removeItem(loadMoreKey);
					}
				}
				delete pagingJSON[gridKey];
			}
		}
		sessionStorage.setItem('wishlistpagination', JSON.stringify(pagingJSON));
	}
}


function restoreState() {
    try {
    	clearSessionStorage();
    	infiniteGridBreak();
        var pagingString = sessionStorage.getItem('wishlistpagination');
    	if (pagingString != null) {
    		var pagingJSON = JSON.parse(pagingString);
    		if ('url' in pagingJSON && pagingJSON.url == window.location.href) {
    			var gridKeys = Object.keys(pagingJSON);
    			for (var i = 0; i < gridKeys.length; i++) {
    				var gridKey = gridKeys[i];
    				if (gridKey != 'url') {
    					var loadMoreKeys = Object.keys(pagingJSON[gridKey]);
    					for (var j = 0; j < loadMoreKeys.length; j++) {
    						var loadMoreKey = loadMoreKeys[j];
    						var json = sessionStorage.getItem(pagingJSON[gridKey][loadMoreKey]);
    						var pages = JSON.parse(json);
    				        if (pages && pages.map) {
    				            pages.map(function (page) {
    				                updateSearchPageUI(page.response, page.scrollTop, gridKey);
    				            });
    				        } else {
    				            sessionStorage.removeItem(pagingJSON[gridKey][loadMoreKey]);
    				        }
    					}
    				}
    			}
    		}
        }
        window._rlc.inView(); // adds all .rlc-block elements to the "inView" watcher
    	window._rlc.lazyLoader(); // adds "inView" listener to lazy load images
    } catch (e) {
        sessionStorage.removeItem(window.location.href);
    }
}

function infiniteGridBreak() {
	var loadedHeight = 0;
	$('.productGrid[data-loading-state="loaded"]').each(function() {
		loadedHeight += $(this).height();
	});
	if ((loadedHeight - $(window).scrollTop() - (screen.height - 200)) <= 0) {
		var loadingPlaceHolder = $('.productGrid[data-loading-state="unloaded"]:first');
		if (loadingPlaceHolder.length > 0) {
			loadingPlaceHolder.attr('data-loading-state', 'loaded');
			$.ajax({
		        type: 'GET',
		        dataType: 'html',
		        url: loadingPlaceHolder.attr('url'),
		        success: function (response) {
                    loadingPlaceHolder.html(response); // Notes current product count in URL.
		        }
		    });
		} else {
            $(window).off('scroll', infiniteGridBreak);
        }
	}
}

exports.init = function () {
    if (pageContext.ns !== undefined && pageContext.ns == 'wishlist') {
        if (SitePreferences.LISTING_INFINITE_SCROLL) {
            $(window).off('scroll', infiniteGridBreak).on('scroll', infiniteGridBreak);
            $(window).off('load', restoreState).on('load', restoreState);
        }
    	initPageLoad();
    }
};